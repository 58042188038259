// Dependencies
import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>Página web no encontrada</h1>
    <p>
      Acabas de llegar a la ruta que no existe <Link to="/">volver atrás</Link>
    </p>
  </>
)

export default NotFoundPage
